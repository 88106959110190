import React from 'react';

export default function Services() {
    return (
        <div>
            <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Education Services</h2>
                        <p style={{ "color": "white" }}>At Dija Academy, we specialize in a comprehensive curriculum from very young age through to graduation, ensuring each student reaches their highest potential.</p>
                    </div>

                    <div className="row">

 {/* Cambridge Curriculum */}
 <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={200}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Cambridge Curriculum</a></h4>
                                <ul>
                                    <li>Primary: 5-11 years.</li>
                                    <li>Secondary 1: 11-14 years.</li>
                                    <li>IGCSE: 14-16 years.</li>
                                    <li>A-Levels: 16-18 years.</li>
                                </ul>
                            </div>
                        </div>

                        {/* International Baccalaureate (IB) */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>International Baccalaureate (IB)</a></h4>
                                <ul>
                                    <li>Primary Years Programme (PYP).</li>
                                    <li>Middle Years Programme (MYP): 11-16 years.</li>
                                    <li>Diploma Programme (DP): 16-19 years.</li>
                                </ul>
                            </div>
                        </div>

                       

                        {/* American Curriculum */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={300}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>American Curriculum</a></h4>
                                <ul>
                                    <li>Up to Grade 12 (K-12).</li>
                                </ul>
                            </div>
                        </div>

                        {/* British National Curriculum */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={400}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>British National Curriculum</a></h4>
                                <ul>
                                    <li>Primary: Key Stages 1 and 2, ages 5-11.</li>
                                    <li>Secondary: Key Stages 3 and 4, ages 11-16.</li>
                                    <li>Sixth Form: Ages 16-18.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Advanced Courses */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={200}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Advanced Courses</a></h4>
                                <ul>
                                    <li>Linear Algebra.</li>
                                    <li>Numerical Analysis.</li>
                                    <li>Machine Learning.</li>
                                    <li>Advanced Mathematical Concepts.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Online Learning */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={300}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Online Learning</a></h4>
                                <ul>
                                    <li>Interactive Digital Resources.</li>
                                    <li>Engaging Virtual Classroom Experience.</li>
                                    <li>Flexible Learning via Google Meet.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Individual Tutoring */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={400}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Individual Tutoring</a></h4>
                                <ul>
                                    <li>Personalized Lesson Plans.</li>
                                    <li>Dedicated Tutor Support.</li>
                                    <li>Targeted Skill Development.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Group Tutoring */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={500}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Group Tutoring</a></h4>
                                <ul>
                                    <li>Affordable Learning Sessions.</li>
                                    <li>Collaborative Learning Environment.</li>
                                    <li>Peer Discussions and Interactions.</li>
                                </ul>
                            </div>
                        </div>

                        {/* On-Site Learning */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={600}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>On-Site Learning</a></h4>
                                <ul>
                                    <li>Interactive Classroom Sessions.</li>
                                    <li>Skilled and Experienced Educators.</li>
                                    <li>State-of-the-art Facilities.</li>
                                </ul>
                            </div>
                        </div>

                        {/* ... (rest of the component as before) */}

                        {/* Block Courses */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={900}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Block Courses</a></h4>
                                <ul>
                                    <li>Intensive Study Sessions.</li>
                                    <li>Designed for Rapid Progress.</li>
                                    <li>Specialized Topic Coverage.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Crash Courses */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={1000}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Crash Courses</a></h4>
                                <ul>
                                    <li>Quick & Comprehensive Overview.</li>
                                    <li>Perfect for Exam Preparations.</li>
                                    <li>Highly Focused Curriculum.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Summer Pre-preparation */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={1100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Summer Pre-preparation</a></h4>
                                <ul>
                                    <li>Get a Head Start for the Year.</li>
                                    <li>Review & Preview of Subjects.</li>
                                    <li>Engaging Activities & Learning.</li>
                                </ul>
                            </div>
                        </div>

{/* ... (rest of the component as before) */}

                        {/* Support & Charity */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={700}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Support & Charity</a></h4>
                                <ul>
                                    <li>Supporting Learning in Underserved Countries.</li>
                                    <li>Food & Educational Aid.</li>
                                    <li>Partnerships with Charity Organizations.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Life-Long Learning Philosophy */}
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={800}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Life-Long Learning Philosophy</a></h4>
                                <ul>
                                    <li>Instilling a Passion for Learning.</li>
                                    <li>Building Rational & Critical Thinkers.</li>
                                    <li>Nurturing Curiosity & Exploration.</li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}
