export const schema = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "additionalType": [
        "https://schema.org/LocalBusiness",
        "https://schema.org/AdultEducationCenter",
        "https://schema.org/School",
        "https://schema.org/Course",
        "https://schema.org/ProfessionalService",
        "http://www.productontology.org/id/Tutoring",
        "http://www.productontology.org/id/Math_education",
        "http://www.productontology.org/id/Physics_Tutor",
        "http://www.productontology.org/id/Math_Tutor"
      ],
    "name": "Dija Academy",
    "description": "Dija Academy offers a comprehensive range of high-quality tutoring services, including math tutoring, science tutoring, physics tutoring, and programming courses in Oslo and Zurich.",
    "url": "https://www.dijaacademy.com",
    "telephone": "[004748240032]",
    "location": [
      {
        "@type": "Place",
        "name": "Dija Academy - Oslo",
        "telephone": "[004748240032]",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "[Eilert Sundts Gate 11A, 0259]",
          "addressLocality": "Oslo",
          "addressCountry": "Norway"
        }
      },
      {
        "@type": "Place",
        "name": "Dija Academy - Zurich",
        "telephone": "[]",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "[Zurich street address]",
          "addressLocality": "Zurich",
          "addressCountry": "Switzerland"
        }
      }
    ],
    "brand": {
      "@type": "Brand",
      "name": "Dija Academy",
      "description": "Dija Academy is a trusted name in personalized tutoring and educational services."
    },
    "course": [
        {
            "@type": "Course",
            "name": "Cambridge IGCSE Tutoring",
            "description": "Specialized tutoring for Cambridge IGCSE subjects",
            "provider": "Dija Academy"
          },
          {
            "@type": "Course",
            "name": "American Curriculum Tutoring",
            "description": "Tailored tutoring services for students following the American curriculum",
            "provider": "Dija Academy"
          },
          {
            "@type": "Course",
            "name": "IB Tutoring",
            "description": "Expert tutoring services for International Baccalaureate subjects",
            "provider": "Dija Academy"
          },
      {
        "@type": "Course",
        "name": "Math Tutoring",
        "description": "One-on-one and group tutoring sessions for algebra, geometry, calculus, and more.",
        "provider": "Dija Academy"
      },
      {
        "@type": "Course",
        "name": "Science Tutoring",
        "description": "Comprehensive tutoring in physics, chemistry, biology, and earth sciences.",
        "provider": "Dija Academy"
      },
      {
        "@type": "Course",
        "name": "Programming Courses",
        "description": "Learn to code with languages like Python, Java, and C++ through our specialized courses.",
        "provider": "Dija Academy"
      }
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": "150"
    }
  };

  export const metaTags =  [{name:"description", content:"Dija Academy offers a comprehensive range of high-quality tutoring services,   including math tutoring, science tutoring, and programming courses in Oslo and Zurich."},
  {name:"keywords", content:"Cambridge, IGCSE, American, IB,tutoring, math tutor, physics tutor, private tutor, one to one tutor,  science, programming, Oslo, Zurich, Dija Academy"}]
  


  export const openGraphTags = [
    { property: "og:title", content: "Dija Academy - High-Quality Tutoring Services" },
    { property: "og:description", content: "Dija Academy offers a comprehensive range of high-quality tutoring services, including math tutoring, science tutoring, and programming courses in Oslo and Zurich." },
    { property: "og:type", content: "website" },
    { property: "og:url" , content: "https://www.dijaacademy.com"},

  ];