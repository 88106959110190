import React from 'react'
import AboutUs from './AboutUs'
import GetStarted from './GetStarted'
import Services from './Services'
import Career from './Career'
import Hero from './Hero'
export default function Main() {
    return (
        <div>
            <main id="main">
            <Hero></Hero>
                <AboutUs/>
                {/* ======= Cliens Section ======= */}
                {/*             <section id="cliens" className="cliens section-bg">
                <div className="container">
                    <div className="row" data-aos="zoom-in">
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-1.png" className="img-fluid" alt />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-2.png" className="img-fluid" alt />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-3.png" className="img-fluid" alt />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-4.png" className="img-fluid" alt />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-5.png" className="img-fluid" alt />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/clients/client-6.png" className="img-fluid" alt />
                        </div>
                    </div>
                </div>
            </section> */}
                {/* ======= About Us Section ======= */}
 
                {/* ======= Contact Section ======= */}
                <Services/>
                <Career/>
                <GetStarted/>
              
            </main>

        </div>
    )
}
