import React from 'react';

export default function Career() {
    return (
        <div>
            <section id="career" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Career at Dija Academy</h2>
                    </div>
                    <div>
                        <p>
                            At Dija Academy, we embark on a mission to shape the future of IGCSE education. Our foundation is cemented with expert teachings in Math and Physics. As we continue to grow and evolve, we seek passionate educators to join our team and expand our offerings.
                        </p>
                        <p>
                            If you have an ardor for teaching and a knack for making complex subjects comprehensible, we invite you to be a part of our journey. While we are actively looking for educators across a range of IGCSE subjects, even if your expertise lies outside of Math and Physics, your initiative will be highly regarded. Together, we aim to create an educational experience that's both enlightening and engaging for our students.
                        </p>
                        <p>
                            Beyond subject expertise, we value educators who can nurture curiosity, foster critical thinking, and inspire students to reach their highest potential. If our vision resonates with you and you're eager to make a significant impact, we would be delighted to hear from you.
                        </p>
                        <p>
                            To embark on this exciting teaching journey with Dija Academy, send us your application comprising a CV, Cover letter, recommendation letters, and at least two references. Direct your applications to drmoe@dijaacademyoslo.com. Should you have any queries concerning the role or application process, please reach out by given email.
                            Please ensure you meet our minimum qualifications for the position.
                        </p>
                    </div>
                    <div className="section-title">
                        <h4>Minimum Qualifications</h4>
                    </div>
                    <div className="row content" >
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                    B.Sc. or M.Sc. in education, or a relevant field.
                                </li>
                                <li><i className="ri-check-double-line" />Public university graduates with a minimum overall grade of C-good. Candidates from esteemed private institutions will also be considered.
                                </li>
                                <li><i className="ri-check-double-line" /> 4+ years of teaching experience in the IGCSE curriculum or other relevant educational frameworks.
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* You can add more job roles and descriptions similar to the above structure if you wish */}
                </div>
            </section>
        </div>
    )
}
